import SafariFlexGap from '@crm/components/safari-components/safari-flex-gap';
import Typography from '@crm/components/typography';
import useMediaQuery from '@crm/hooks/use-media-query/use-media-query';
import React from 'react';
import styled, {useTheme} from 'styled-components';
import HorizontalSpacing from '../horizontal-spacing';

type Variant = 'imageLeft' | 'imageRight';

interface Props {
    variant?: Variant;
    title?: string | React.ReactNode;
    imageSection: React.ReactNode | React.ReactNode[];
    textSection: React.ReactNode | React.ReactNode[];
    note?: { text: string; color: string };
    className?: string;
}

const Note = styled.div<{ color: string }>`
    border-radius: 24px;
    display: inline-block;
    border: 1px solid ${props => props.color};
    ${({theme}) => theme.typography.body}
    font-size: 8px;
    padding: 2px 10px;
    margin-bottom: 13px;
    color: ${props => props.color};

    ${({theme}) => theme.breakpoints.down('tablet')} {
        margin-top: 0;
    }
`;

const imageContainerClasses = 'w-full tablet:max-w-2xl tablet:w-1/2 tablet:self-center';
const textContainerClasses = 'w-full tablet:w-1/2 self-center';

export const LargeImageSection = (props: Props) => {
    const {variant = 'imageRight', title, imageSection, className, textSection, note} = props;

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('tablet'));

    const flexWrapDirection = variant == 'imageRight' ? 'flex-wrap-reverse' : 'flex-wrap';

    return <HorizontalSpacing>
        {matches && title &&
            <Typography className="text-center py-[60px]" variant="h2">
                {title}
            </Typography>
        }
        <SafariFlexGap
            className={`flex tablet:flex-nowrap justify-center tablet:gap-12 ${flexWrapDirection} ${className}`}
            style={{gridGap: matches ? '0rem' : '3rem'}}
        >
            <div className={variant == 'imageLeft' ? imageContainerClasses : textContainerClasses}>
                {note && variant == 'imageRight' &&
                    <div className="flex justify-center tablet:justify-start">
                        <Note color={note.color}>{note.text}</Note>
                    </div>
                }
                {variant == 'imageLeft' ? imageSection : textSection}
            </div>
            <div className={`${variant == 'imageRight' ? imageContainerClasses : textContainerClasses} flex`}>
                <div className='w-full'>
                    {note && variant == 'imageLeft' &&
                        <div className="flex justify-center tablet:justify-start">
                            <Note color={note.color}>
                                {note.text}
                            </Note>
                        </div>
                    }
                    {!matches && title &&
                        <Typography className="pb-5" variant="h2">
                            {title}
                        </Typography>
                    }
                    {variant == 'imageRight' ? imageSection : textSection}
                </div>
            </div>
        </SafariFlexGap>
    </HorizontalSpacing>;
};

import Typography from '@crm/components/typography';
import styled from 'styled-components';
import Image, {StaticImageData} from 'next/image';
import {motion, useInView} from 'framer-motion';
import React, {ReactElement, useRef, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import tw from 'tailwind-styled-components';
import ScrollContainer from 'react-indiana-drag-scroll';
import useXScrollListener from 'hooks/use-x-scroll-listener';
import HorizontalSpacing from '../horizontal-spacing';
import SafariFlexGap from '@crm/components/safari-components/safari-flex-gap';

export interface SlideElement {
    imageSrc: string | StaticImageData;
    imageAlt: string;
    title: string;
    text: string;
    style?: React.CSSProperties;
}

interface Props {
    title: string | ReactElement;
    roundedImages?: boolean
    slideElements: SlideElement[];
}

const ChevronIcon = styled(FontAwesomeIcon)<{ $active: boolean }>`
    height: fit-content;
    width: 100%;

    &:hover {
        cursor: ${props => props.$active ? `pointer` : `normal`};
        color: ${props => props.$active ? `white` : undefined};
    }

    opacity: ${props => !props.$active ? `50%` : undefined};
`;

const ChevronContainer = tw.div`
  flex-auto
  items-center
  mobile:w-16
  flex
  absolute
  z-10
  bg-turquoise
  bg-opacity-50 
  h-full
  top-0
  px-5
  opacity-0
  mobile:opacity-100
  w-0
`;

export const CardsScrollSection = (props: Props) => {
    const {slideElements, title, roundedImages} = props;

    const [scrollCursor, setScrollCursor] = useState<'start' | 'middle' | 'end'>('start');
    const [animationComplete, setAnimationComplete] = useState(false);

    const scrollContainerRef = useRef<HTMLElement>(null);
    const chevronRef = useRef<HTMLDivElement>(null);
    const chevronInView = useInView(chevronRef);

    const initialScroll = {
        x: '100vw',
        opacity: 1
    };

    const endScroll = {
        x: 0,
        opacity: 1
    };

    /**
     * Scrolls the elements inside the ScrollContainer.
     * @param scrollAmount The sign determines the direction. negative scrolls left and positive scrolls right.
     */
    const handleOnChevronClick = (scrollAmount = 300) => {
        const scrollContainer = scrollContainerRef.current;
        const maxScroll = scrollContainer && scrollContainer.scrollWidth - scrollContainer.offsetWidth;
        const minScroll = 0;
        const currentOffset = scrollContainer?.scrollLeft;

        let scrollToPos = 0;
        currentOffset != undefined && (scrollToPos = currentOffset + scrollAmount);

        if (scrollToPos <= minScroll) {
            scrollToPos = minScroll;
        }

        if (maxScroll && scrollToPos >= maxScroll) {
            scrollToPos = maxScroll;
        }
        scrollContainerRef.current?.scroll(scrollToPos, 0);
    };

    /** setScrollCursor to indicate whether the scrollContainer has been scrolled all the way to the start, somewhere in the middle or end. */
    const handleScrollCursor = () => {
        const scrollContainer = scrollContainerRef.current;
        const maxScroll =
            scrollContainer && scrollContainer.scrollWidth - scrollContainer.offsetWidth - 1;
        const minScroll = 0;
        const currentOffset = scrollContainer?.scrollLeft;
        if (maxScroll && currentOffset != undefined) {
            if (currentOffset <= minScroll) {
                setScrollCursor('start');
            } else if (currentOffset >= maxScroll) {
                setScrollCursor('end');
            } else {
                setScrollCursor('middle');
            }
        }
    };

    const scrollListener = useXScrollListener(scrollContainerRef?.current, handleScrollCursor);
    const noScroll = !scrollListener.getScrolling;

    return <div className="w-full h-fit bg-turquoise text-darkBlue pb-20">
        <HorizontalSpacing>
            <Typography variant="h2" className="py-20 text-center">
                {title}
            </Typography>
        </HorizontalSpacing>
        <div
            className="relative"
        >
            <ChevronContainer className={`left-0 ${noScroll && `mobile:w-0 mobile:opacity-0`}`}>
                <div ref={chevronRef}>
                    <ChevronIcon
                        icon={faChevronLeft}
                        $active={scrollCursor != 'start'}
                        onClick={() => handleOnChevronClick(-396)}
                    />
                </div>
            </ChevronContainer>
            <ScrollContainer
                nativeMobileScroll
                innerRef={scrollContainerRef}
                className={`scrollContainerRef scroll-smooth overflow-x-auto flex snap-x snap-mandatory mobile:snap-none ${
                    noScroll ? `justify-center` : `pl-12 mobile:pl-24`
                } ${animationComplete && `w-full`} `}
            >
                <motion.div
                    initial={initialScroll}
                    animate={chevronInView && endScroll}
                    viewport={{once: true}}
                    transition={{delay: 0, duration: 2, type: 'spring'}}
                    onAnimationComplete={() => setAnimationComplete(true)}
                >
                    <SafariFlexGap className="flex gap-20 relative">
                        {slideElements.map(element =>
                            <div
                                className={`w-[300px] flex-none mobile:snap-none max-w-[60vw]`}
                                key={element.title + element.text}
                                style={{scrollSnapAlign: animationComplete ? 'center' : 'none'}}
                            >
                                <div
                                    className={`flex justify-center mb-5 w-full ${roundedImages ? 'rounded-full overflow-hidden' : ''}`}>
                                    <Image src={element.imageSrc} className="w-full" alt={element.imageAlt}/>
                                </div>
                                <div className="h-16 flex items-end justify-center text-center">
                                    <Typography variant="h3" className="whitespace-pre-line">
                                        {element.title}
                                    </Typography>
                                </div>
                                <div className="mt-3">
                                    <Typography
                                        style={{fontSize: '13px'}}
                                        className="whitespace-pre-line text-center"
                                    >
                                        {element.text}
                                    </Typography>
                                </div>
                            </div>
                        )}
                        {/* Div is used to give some space after last element*/}
                        {!noScroll && <div className="w-0 mobile:w-3 flex-none"></div>}
                    </SafariFlexGap>
                </motion.div>
            </ScrollContainer>
            <ChevronContainer className={`right-0 ${noScroll ? `mobile:w-0 mobile:opacity-0` : ``}`}>
                <ChevronIcon
                    icon={faChevronRight}
                    $active={scrollCursor != 'end'}
                    onClick={() => handleOnChevronClick(396)}
                />
            </ChevronContainer>
        </div>
    </div>;
};

import styled from 'styled-components';
import Typography from '../typography';
import Image, {StaticImageData} from "next/legacy/image";
import React from 'react';
import {unEscapeBackslash} from '@crm/utils/helpers';

interface Props {
    imageSrc: string | StaticImageData;
    imageAlt: string;
    imageClassName?: string;
    title: string;
    text: string;
    textClassName?: string;
    className?: string;
    spaceBetween?: number;
}

const ItemTitle = styled(Typography)`
    margin-top: min(72.5px, 10%);
    margin-bottom: min(10px, 10%);
`;

const CardImage: React.FC<Props> = (props: Props) => {
    const {
        imageSrc,
        imageAlt,
        title,
        text,
        className,
        spaceBetween = 0,
        imageClassName = "w-[260px]",
        textClassName = "w-full"
    } = props;
    return <div className={className + " flex flex-col items-center text-center"}>
        <div className={imageClassName} style={{paddingBottom: spaceBetween}}>
            <Image src={imageSrc} className="object-cover" alt={imageAlt}/>
        </div>
        <div className="h-20 flex items-end justify-center w-full">
            <ItemTitle variant="h3" className="whitespace-pre-line">
                {unEscapeBackslash(title)}
            </ItemTitle>
        </div>
        <div className={textClassName}>
            <Typography style={{fontSize: '13px'}}
                        className="whitespace-pre-line">{unEscapeBackslash(text)}</Typography>
        </div>
    </div>;
};

export default CardImage;

import {useEffect, useState} from 'react';

/**
 * Listens for scroll event on obj and callback onScroll
 * @param obj
 * @param onScroll
 * @returns getScrolling is true if the obj has x-overflow
 */
const useXScrollListener = (obj: HTMLElement | null, onScroll: () => void) => {
    const [scroll, setScroll] = useState<boolean>()

    useEffect(() => {
        const listenScroll = () => obj?.addEventListener('scroll', onScroll);
        listenScroll();
        return () => obj?.removeEventListener('scroll', onScroll);
    });

    useEffect(() => {
        if (obj) {
            setScroll(obj.scrollWidth - obj.offsetWidth > obj.clientWidth)
        }
    }, [obj])

    return {
        getScrolling: scroll
    }
};

export default useXScrollListener;

import styled from "styled-components";

//** Adds margin on safari because gap is not supported in flex-box */
const SafariFlexGap = styled.div<{ gap?: string, gapMobile?: string }>`
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    > div:not(:last-child) {
      ${({theme}) => theme.breakpoints.up('mobile')} {
        margin-right: ${props => props.gap ?? '3rem'};
      }

      ${({theme}) => theme.breakpoints.down('mobile')} {
        margin-right: ${props => props.gapMobile ? props.gapMobile : ''};
      }
    }
  }
`

export default SafariFlexGap
import React, {HTMLAttributes} from 'react';
import styled, {DefaultTheme} from 'styled-components';
import {ThemeColorType, themeColorTypes} from '@crm/theme/light-theme';
import {Arrow} from '../../utils/images';
import {ColorTypes} from '../typography/typography';

interface Props extends HTMLAttributes<HTMLLIElement> {
    color?: ColorTypes;
    children: React.ReactElement;
    bullet?: any;
    styles?: {
        root?: string;
        bulletContainer?: string;
    };
    reverse?: boolean;
}

const alternativeColorTypes = [
    'initial',
    'inherit',
    'primary',
    'textPrimary',
    'textSecondary',
    'error'
] as const;

type AlternativeColorTypes = typeof alternativeColorTypes[number];

const getColor = (colorType: ColorTypes, theme: DefaultTheme) => {
    if (themeColorTypes.includes(colorType as ThemeColorType)) {
        return theme.palette.common[colorType as ThemeColorType];
    } else if (alternativeColorTypes.includes(colorType as AlternativeColorTypes)) {
        switch (colorType as AlternativeColorTypes) {
            case 'initial':
                return 'initial';
            case 'inherit':
                return 'inherit';
            case 'primary':
                return theme.palette.primary.main;
            case 'textPrimary':
                return theme.palette.text.primary;
            case 'textSecondary':
                return theme.palette.text.secondary;
            case 'error':
                return theme.palette.error.main;

            default:
                return 'initial';
        }
    }
};

interface BulletRootProps {
    styles?: string;
}

const BulletRoot = styled.li<Props & BulletRootProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({color, theme}) => color && `color: ${getColor(color, theme)}`};

  ${({styles}) => styles && styles};
`;

interface ArrowWrapperProps {
    styles?: string;
}

const ArrowWrapper = styled.span<Props & ArrowWrapperProps>`
  display: inline-flex;
  align-items: center;
  width: 17px;
  margin-right: 10px;
  height: 100%;
  position: relative;
  vertical-align: middle;
  flex-shrink: 0;

  & path {
    fill: ${({color, theme}) => color && getColor(color, theme)};
  }

  ${({styles}) => styles && styles};
`;

// Todo: Should clone element and not use wrapper, like here:
// Todo: https://codepen.io/team/css-tricks/pen/QNoBVq
export const Bullet: React.FC<Props> = (props: Props) => {
    const {children, color = 'primary', bullet = <Arrow className="h-full w-full"/>, styles, ...rest} = props;

    return <BulletRoot color={color} styles={styles?.root} {...rest}>
        <>
            {props.reverse && children}
            <ArrowWrapper styles={styles?.bulletContainer} color={color}>
                {bullet}
            </ArrowWrapper>
            {!props.reverse && children}
        </>
    </BulletRoot>;
};
